import React from "react";
import styled from "styled-components";
import { GoBell } from "react-icons/go";
import { useAuthContext } from "contexts";

export const IconButton = ({
  icon,
  text,
  avatar,
  onClick = () => {},
  ...props
}) => {
  const { userInfo } = useAuthContext();

  return (
    <Container
      onClick={onClick}
      buttonType={icon ? "icon" : userInfo.rank === "adm" ? "admin" : "text"}
      {...props}
    >
      {avatar ? (
        <Avatar src={avatar} alt="avatar" />
      ) : userInfo.rank !== "adm" && text ? (
        <span>{text}</span>
      ) : icon === "bell" ? (
        <GoBell size={24} />
      ) : (
        ""
      )}
    </Container>
  );
};

const Container = styled.button`
  width: 35px;
  height: 35px;
  border-radius: 50%;
  display: flex;
  color: ${(props) =>
    props.acitve
      ? "black"
      : props.disabled
      ? props.theme.palette.secondary
      : props.theme.palette.white};
  background: ${(props) =>
    props.buttonType === "admin"
      ? "repeating-linear-gradient(45deg,#f7c128,  #f7c128 6px,  #0A0A0A 6px,  #0A0A0A 12px);"
      : props.buttonType === "text"
      ? props.theme.palette.primary
      : "transparent"};
  border: none;
  transition: all 300ms ease;
  padding: 0;
  box-sizing: border-box;

  &:hover {
    /* border: 1px solid ${(props) => props.theme.palette.primaryHover}; */
    opacity: 0.7;
  }

  span {
    margin: auto;
    font-size: 20px;
    line-height: 20px;
  }
`;
const Avatar = styled.img`
  width: 35px;
  height: 35px;
  border-radius: 50%;
`;
