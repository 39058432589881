import React, { useEffect } from "react";
import styled from "styled-components";
import { Header, Footer, PaymentStatusModal } from "components";
import { useModal } from "hooks";
import { useLocation } from "@reach/router";
import { parse } from "query-string";

export const MainLayout = ({ children }) => {
  const paymentModal = useModal();
  const location = useLocation();
  const parsed = parse(location.search);

  useEffect(() => {
    if (
      parsed.payment_status === "success" ||
      parsed.payment_status === "cancel"
    ) {
      paymentModal.openModal();
    }
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [parsed.payment_status]);
  return (
    <StyledLayout>
      <Header />
      {children}
      <Footer />
      <PaymentStatusModal status={parsed.payment_status} {...paymentModal} />
    </StyledLayout>
  );
};

const StyledLayout = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;
`;
