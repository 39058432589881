import { useRef } from "react";
import classNames from "classnames";
import { AiFillCaretDown } from "react-icons/ai";
import "./style.scss";

export const DropDown = ({
  data = [],
  value,
  onChange,
  label,
  className,
  backgroundColor = "#eee",
  width = 160,
}) => {
  const containerRef = useRef(null);

  const handleChange = (params) => {
    if (params !== value) {
      onChange(params);
    }
    containerRef.current && containerRef.current.blur();
  };
  return (
    <div className={classNames("d-flex", className)}>
      {label && <span className="select-label my-auto">{label}</span>}
      <div
        ref={containerRef}
        tabIndex={0}
        className="select-container"
        style={{ width }}
      >
        <div className="menu-button" style={{ width }}>
          <span className="mr-auto">
            {data.length > 0
              ? data.find((item) => item.id === value)?.title
              : ""}
          </span>
          <AiFillCaretDown
            color="white"
            className="menu-button__drop-down"
            alt="drop-down"
          />
        </div>
        <div className="menu-dropdown">
          {data.map((item) => {
            return (
              <div
                className={classNames(
                  "menu-item",
                  item.id === value ? "active" : ""
                )}
                key={item.id}
                onClick={() => handleChange(item.id)}
                style={{ width }}
              >
                {item.title}
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};
