import React, { useState, useEffect } from "react";
import styled from "styled-components";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import { capitalize } from "lodash";
import { Section } from "./../components";
export const SummarySection = ({ children, post, isLoading }) => {
  const [summary, setSummary] = useState("");

  useEffect(() => {
    if (post.cert) {
      let str = "";
      if (post?.cert?.meta?.description?.opener) {
        str += capitalize(post?.cert?.meta?.description?.opener || "");
      }
      if (post?.cert?.meta?.stt?.sentence) {
        str += ` ${post?.cert?.meta?.stt?.sentence || ""}`;
      }
      if (post?.cert?.meta?.faces?.tone) {
        str += ` with a ${post?.cert?.meta?.faces?.tone} mood`;
        if (post?.cert?.meta?.sentiment?.mood) {
          str += ` and ${post?.cert?.meta?.sentiment?.mood} tone.`;
        } else {
          str += ".";
        }
      } else if (post?.cert?.meta?.sentiment?.mood) {
        str += ` with a ${post?.cert?.meta?.sentiment?.mood} tone.`;
      }
      if (post?.cert?.meta?.description?.closer) {
        str += ` ${capitalize(post?.cert?.meta?.description?.closer)}`;
      }
      setSummary(capitalize(str.trim()));
    }
    return () => {};
  }, [post]);

  return isLoading ? (
    <Section title="Summary">
      <SkeletonTheme color="#202020" highlightColor="#444">
        <Skeleton count={3} />
      </SkeletonTheme>
    </Section>
  ) : (
    <Section title="Summary">
      <div className="mb-1">{summary}</div>
      <Container className="row">
        {post?.cert?.meta?.categories &&
          post?.cert?.meta?.categories.filter((a) => a).length > 0 && (
            <Box className="d-flex ">
              <Label>Industry:</Label>
              <Value>
                {post.cert.meta.categories.map((cat, index) => {
                  return (
                    <Link
                      href={`/home/?query=${cat}`}
                      key={`${index}key`}
                      target="_blank"
                    >
                      {cat}
                    </Link>
                  );
                })}
              </Value>
            </Box>
          )}
        {post?.cert?.meta?.category_group && (
          <Box className="d-flex ">
            <Label>Category Group:</Label>
            <Value>
              <Link
                href={`/home/?query=${post.cert.meta.category_group}`}
                target="_blank"
              >
                {post.cert.meta.category_group}
              </Link>
            </Value>
          </Box>
        )}
        {post?.cert?.meta?.category && (
          <Box className="d-flex ">
            <Label>Category Group:</Label>
            <Value>
              <Link
                href={`/home/?query=${post.cert.meta.category}`}
                target="_blank"
              >
                {post.cert.meta.category}
              </Link>
            </Value>
          </Box>
        )}
        {post?.cert?.meta?.sub_category && (
          <Box className="d-flex ">
            <Label>Sub-Category:</Label>
            <Value>
              <Link
                href={`/home/?query=${post.cert.meta.sub_category}`}
                target="_blank"
              >
                {post.cert.meta.sub_category}
              </Link>
            </Value>
          </Box>
        )}
        {post?.cert?.meta?.big_brand && (
          <Box className="d-flex ">
            <Label>Advertiser:</Label>
            <Value>
              <Link
                href={`/home/?query=${post.cert.meta.big_brand}`}
                target="_blank"
              >
                {post.cert.meta.big_brand}
              </Link>
            </Value>
          </Box>
        )}
        {post?.cert?.meta?.platform && (
          <Box className="d-flex ">
            <Label>Platform:</Label>
            <Value>
              <Link
                href={`/home/?query=${post.cert.meta.platform}`}
                target="_blank"
              >
                {post.cert.meta.platform}
              </Link>
            </Value>
          </Box>
        )}
        {post?.cert?.meta?.network && (
          <Box className="d-flex ">
            <Label>Platform:</Label>
            <Value>
              <Link
                href={`/home/?query=${post.cert.meta.network}`}
                target="_blank"
              >
                {post.cert.meta.network}
              </Link>
            </Value>
          </Box>
        )}
        {post?.cert?.meta?.socials && post?.cert?.meta?.socials.length > 0 && (
          <Box className="d-flex ">
            <Label>Socials:</Label>
            <Value>
              {post.cert.meta.socials.map((social) => (
                <div key={social} className="mr-3">
                  {social}
                </div>
              ))}
            </Value>
          </Box>
        )}
        {post?.data?.pre?.label && (
          <Box className="d-flex ">
            <Label>Label:</Label>
            <Value>
              <a href={post?.data?.pre?.label} target="_blank" rel="noreferrer">
                {post?.data?.pre?.label}
              </a>
            </Value>
          </Box>
        )}
        {post?.cert?.meta?.domains && post?.cert?.meta?.domains.length > 0 && (
          <Box className="d-flex ">
            <Label>Domains:</Label>
            <Value>
              {post.cert.meta.domains.map((domain, index) => (
                <div key={`${index}key`}>{domain}</div>
              ))}
            </Value>
          </Box>
        )}
        {post?.cert?.meta?.urls && post?.cert?.meta?.urls.length > 0 && (
          <Box className="d-flex ">
            <Label>URLs:</Label>
            <Value>
              {post?.cert?.meta?.urls.map((url, index) => (
                <a
                  key={`${index}key`}
                  href={post?.cert?.meta?.urls}
                  target="_blank"
                  rel="noreferrer"
                >
                  {post?.cert?.meta?.urls}
                </a>
              ))}
            </Value>
          </Box>
        )}
        {post?.cert?.meta?.retailer && (
          <Box className="d-flex ">
            <Label>Retailer:</Label>
            <Value>{post.cert.meta.retailer}</Value>
          </Box>
        )}
        {post?.data?.pre?.hint && (
          <Box className="d-flex ">
            <Label>Banner:</Label>
            <Value>{post.data.pre.hint}</Value>
          </Box>
        )}
        {post?.data?.pre?.whisper && (
          <Box className="d-flex ">
            <Label>Snippet:</Label>
            <Value>{post.data.pre.whisper}</Value>
          </Box>
        )}
        {post?.source && post?.source.startsWith("http") && (
          <Box className="d-flex ">
            <Label>Source:</Label>
            <Value>
              <a href={post.source} rel="noreferrer" target="_blank">
                {post?.source}
              </a>
            </Value>
          </Box>
        )}
        {post?.data?.pre?.landing && (
          <Box className="d-flex ">
            <Label>Landing:</Label>
            <Value>{post.data.pre.landing}</Value>
          </Box>
        )}
        {post?.data?.pre?.screen && (
          <Box className="d-flex ">
            <Label>Screenshot:</Label>
            <Value>{post.data.pre.screen}</Value>
          </Box>
        )}
        {post?.data?.pre?.impressions && (
          <Box className="d-flex ">
            <Label>Impressions:</Label>
            <Value>{post.data.pre.impressions}</Value>
          </Box>
        )}
        {post?.data?.pre?.spend_est && (
          <Box className="d-flex ">
            <Label>Spend:</Label>
            <Value>{post.data.pre.spend_est}</Value>
          </Box>
        )}
        {post?.cert?.meta?.faces?.genders &&
        post?.cert?.meta?.faces?.genders.length > 0 ? (
          <Box className="d-flex ">
            <Label>People:</Label>
            <Value>
              <span>
                {post?.cert?.meta?.faces?.ages?.low &&
                  post?.cert?.meta?.faces?.ages?.high &&
                  `Age: ${post.cert.meta.faces.ages.low} - ${post.cert.meta.faces.ages.high}`}
              </span>
              <span>
                {post.cert.meta.faces.genders.map((person, index) => (
                  <span className="mr-2" key={`${index}key`}>
                    {person}
                  </span>
                ))}
              </span>
            </Value>
          </Box>
        ) : post?.cert?.meta?.faces?.ages?.low &&
          post?.cert?.meta?.faces?.ages?.high ? (
          <Box className="d-flex ">
            <Label>People:</Label>
            <Value>
              <span>
                Age: ${post.cert.meta.faces.ages.low} - $
                {post.cert.meta.faces.ages.high}
              </span>
            </Value>
          </Box>
        ) : null}
      </Container>
    </Section>
  );
};

const Label = styled.div`
  color: ${(props) => props.theme.palette.primary};
  width: 140px;
  min-width: 140px;
`;

const Value = styled.div`
  color: ${(props) => props.theme.palette.white};
  word-break: break-all;
  /* display: flex; */
  flex-direction: column;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 320px;
`;

const Box = styled.div`
  background: ${(props) => (props.isEven ? "#191919" : "")};
  margin: 4px;
  padding: 8px;
  width: calc(50% - 8px);
  &:nth-child(4n + 1),
  &:nth-child(4n + 2) {
    background: #191919;
  }
`;

const Link = styled.a`
  color: white;
  transition: all 300ms ease;
  text-decoration: underline;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  &:hover {
    color: ${(props) => props.theme.palette.primary};
  }
`;

const Container = styled.div`
  a {
    color: white;
    transition: all 300ms ease;
    text-decoration: underline;
    &:hover {
      color: ${(props) => props.theme.palette.primary};
    }
  }
`;
