import React from "react";
import styled from "styled-components";
import { Button, Input, Link } from "components";
import { Transition } from "react-transition-group";
import { useInput } from "hooks";
import ReactModal from "react-modal";
import { useAuthContext, useUploadContext, usePostContext } from "contexts";

const customStyles = (state) => {
  return {
    content: {
      padding: 0,
      borderRadius: 0,
      border: "none",
      height: "calc(100% - 70px)",
      width: "fit-content",
      top: 70,
      left: "calc(100% + 20px)",
      boxShadow: "0 2px 5px rgba(0, 0, 0, 0.50)",
      transform:
        state === "entering" || state === "entered"
          ? "translateX(-440px)"
          : state === "exiting" || state === "exited"
          ? "translateX(0px)"
          : "translateX(0px)",
      transition: "all 300ms ease-in-out",
      opacity: state === "entering" || state === "entered" ? 1 : 0,
    },
    overlay: {
      background: "#0A0A0Aa0",
      transition: "all 300ms ease-in-out",
    },
  };
};
export const ProfileModal = ({ open, onClose, onBuy, userName }) => {
  const key = useInput("jfkdlsjgfed5%4345gfd");
  const secret = useInput("mntr4l3qwnm654git=erwhm54wmh;t4h54h43");

  const { userInfo, logoutUser } = useAuthContext();
  const { initUploads } = useUploadContext();
  const { initPosts } = usePostContext();

  const handleLogout = () => {
    logoutUser();
    initUploads();
    initPosts();
  };

  const handleBuyCredit = async () => {
    onBuy();
  };

  return (
    <Transition in={open} timeout={300}>
      {(state) => (
        <ReactModal
          closeTimeoutMS={300}
          isOpen={open}
          style={customStyles(state)}
          onRequestClose={onClose}
          shouldCloseOnOverlayClick
        >
          <Container>
            <Header>
              <span className="my-auto">{userName ?? ""} Profile</span>
            </Header>
            <Content>
              <Text>
                <h3>{userInfo && userInfo.credits}</h3>
                <div className="mt-auto ml-2">Credits Available</div>
              </Text>
              <Button
                width="fit-content"
                onClick={() => handleBuyCredit()}
                size="small"
                className="ml-auto mt-2"
              >
                Buy Credits
              </Button>
              <Text>API Keys</Text>
              <Button width="fit-content" size="small" className="ml-auto mt-2">
                Request API Key
              </Button>
              <Input label="Key" {...key} className="mt-3" />
              <Input label="Secret" {...secret} className="mt-3" />
              <Text className="mt-auto">Issues? Feedback ?</Text>
              <div className="ml-4 mt-3 d-flex">
                Contact Us: &nbsp;
                <Link
                  href="mailto:support@deep.ad"
                  target="_blank"
                  rel="noreferrer"
                  className="my-auto"
                >
                  support@deep.ad
                </Link>
              </div>
              <Text>Version</Text>
              <div className="ml-4 mt-3">Demo Alpha 0.0.0.0</div>
            </Content>
            <Button size="normal" onClick={handleLogout}>
              Logout
            </Button>
          </Container>
        </ReactModal>
      )}
    </Transition>
  );
};

const Container = styled.div`
  background: ${(props) => props.theme.palette.backgrounds};
  color: ${(props) => props.theme.palette.white};
  box-shadow: -3px 0px 6px rgba(0, 0, 0, 0.16);
  font-size: ${(props) => props.theme.font.size.xm};
  width: 420px;
  height: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
`;
const Header = styled.div`
  background: ${(props) => props.theme.palette.items};
  display: flex;
  height: 48px;
  padding: 0 20px;
`;
const Content = styled.div`
  padding: 50px 20px 16px 20px;
  background: ${(props) => props.theme.palette.backgrounds};
  display: flex;
  flex-direction: column;
  flex: 1;
`;
const Text = styled.div`
  border-bottom: 1px solid ${(props) => props.theme.palette.gray};
  font-size: ${(props) => props.theme.font.size.xm};
  color: ${(props) => props.theme.palette.white};
  padding: 4px 8px;
  display: flex;
  h3 {
    font-size: ${(props) => props.theme.font.size.xxl};
    line-height: ${(props) => props.theme.font.size.xl};
    color: ${(props) => props.theme.palette.success};
  }
`;
