import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { MainLayout } from "layouts";
import { SearchBar, ProcessModal } from "components";
import { useModal } from "hooks";
import { uniq } from "lodash";
import { usePostContext, useAuthContext, useUploadContext } from "contexts";
import { isUri } from "valid-url";
import {
  TableHeader,
  FilterSection,
  TableContent,
  FilterHeader,
  UploadURLModal,
} from "./components";

export const HomePage = () => {
  const [queryFilters, setQueryFilters] = useState([]);
  const uploadURLModal = useModal();
  const [sortCol, setSortCol] = useState("");
  const [sortType, setSortType] = useState(0);

  const { posts, queryPosts, filters, isLoading, loadingTime, remainingPosts } =
    usePostContext();
  const { addUploads, openUpload, open } = useUploadContext();
  const { getUserInfo } = useAuthContext();

  const init = async () => {
    // let res = await axios.head("https://google.com", {
    //   headers: { "Access-Control-Allow-Origin": "*" },
    // });
    // console.log("url exists", res);

    if (posts.length === 0) await queryPosts();
    getUserInfo();
    document.addEventListener(
      "paste",
      function (event) {
        const text = event.clipboardData.getData("text");
        const urls = text.split("\n").filter((url) => isUri(url));
        console.log("urls", urls);
        if (urls.length > 0) {
          addUploads(uniq(urls), "url");
          openUpload();
        }
      },
      false
    );
  };

  useEffect(() => {
    init();
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSearch = async (params) => {
    await queryPosts(params);
  };

  const handleUpload = (params) => {
    addUploads(params, "file");
    openUpload();
  };

  const handleUploadURL = () => {
    uploadURLModal.openModal();
  };
  const handleSort = (sortCol, sortType) => {
    setSortCol(sortCol);
    setSortType(sortType);
  };

  const refreshTable = () => {
    queryPosts();
  };

  return (
    <MainLayout>
      <SearchBar
        isLoaded={!isLoading}
        tags={queryFilters}
        onSearch={handleSearch}
        onUploadURL={handleUploadURL}
      />
      <MainContent>
        <SideSection>
          <FilterHeader
            assets={posts?.length ?? 0}
            totalAssets={(posts?.length ?? 0) + remainingPosts}
            loadingTime={loadingTime}
            isLoading={isLoading}
          />
          <FilterSection
            filters={queryFilters}
            onChange={(params) => setQueryFilters(params)}
            filterOptions={filters}
            isLoading={isLoading}
          />
        </SideSection>
        <TableSection>
          <TableHeader
            sortCol={sortCol}
            sortType={sortType}
            onSort={handleSort}
          />
          <TableContent
            data={posts}
            isLoading={isLoading}
            onUpload={handleUpload}
            sortCol={sortCol}
            sortType={sortType}
          />
        </TableSection>
      </MainContent>
      <UploadURLModal {...uploadURLModal} onUploadFinish={refreshTable}>
        Report
      </UploadURLModal>
      <ProcessModal
        title="Uploads Processing"
        type="processing"
        onUploadFinish={refreshTable}
        open={open}
      />
    </MainLayout>
  );
};

const MainContent = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
  overflow: auto;
  background: ${(props) => props.theme.palette.backgrounds};
  border-top: 3px solid ${(props) => props.theme.palette.canvas};
`;

const SideSection = styled.div`
  width: 300px;
  border-right: 3px solid ${(props) => props.theme.palette.canvas};
  display: flex;
  flex-direction: column;
`;
const TableSection = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`;
