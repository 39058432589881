import React from "react";
import styled from "styled-components";

const Container = styled.label`
  display: block;
  position: relative;
  padding-left: 34px;
  margin: 6px 0px 2px 0;
  cursor: pointer;
  color: ${(props) => props.theme.palette.white};
  font-size: ${(props) => props.theme.font.size.s};
  line-height: 16px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  width: fit-content;
  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }
  a {
    color: #004342;
    text-decoration: none;
    &:hover {
      text-decoration: underline;
      font-weight: 600;
    }
  }
`;

const CheckMark = styled.span`
  position: absolute;
  top: 0;
  left: 0;
  height: 16px;
  width: 16px;
  border: 1px solid ${(props) => props.theme.palette.secondary};
  background-color: white;
  &:after {
    content: "";
    position: absolute;
    display: none;
  }
  ${Container}:hover input ~ & {
    transition: all 300ms ease;
    background-color: ${(props) => props.theme.palette.primaryHover};
  }
  ${Container} input:checked ~ & {
    background-color: ${(props) => props.theme.palette.primary};
    border: 1px solid ${(props) => props.theme.palette.primary};
  }
  ${Container} input:checked ~ &:after {
    display: block;
  }
  ${Container} &:after {
    left: 4px;
    top: 1px;
    width: 6px;
    height: 10px;
    border: solid white;
    border-width: 0 2px 2px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }
`;

export const CheckBox = ({
  children,
  className,
  value,
  onChange = () => {},
}) => {
  return (
    <div className={className} style={{ width: "fit-content" }}>
      <Container type={0}>
        {children}
        <input
          type="checkbox"
          checked={value}
          onChange={(e) => onChange(e.target.checked)}
        />
        <CheckMark />
      </Container>
    </div>
  );
};
