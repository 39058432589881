import React, { createContext, useContext, useReducer, useEffect } from "react";
import { useLocalstorage } from "rooks";
import AuthReducer from "./AuthReducer";
import { navigate } from "@reach/router";
import { TOKEN_EXPIRATION } from "config";
import { getUserInfoApi } from "api";
import { clearAllCookies } from "utils";
import { isEqual } from "lodash";
import { LOGIN, LOGOUT, GET_USER_INFO, SYNC_REQUEST } from "./../types";
export const authInitState = {
  isLogined: false,
  userInfo: {},
};

export const AuthContext = createContext(authInitState);
export const useAuthContext = () => useContext(AuthContext);
const STORAGE_KEY = "user-state";

export const AuthProvider = ({ children }) => {
  const initialState = {
    isLogined: false,
    userInfo: {},
  };
  const [value, set] = useLocalstorage(STORAGE_KEY, initialState);
  const [state, dispatch] = useReducer(AuthReducer, value);

  useEffect(() => {
    const interval = setInterval(() => {
      const now = new Date();
      // console.log(
      //   "check",
      //   now.getTime(),
      //   parseInt(localStorage.getItem("brandsense_token_expire"))
      // );
      if (
        localStorage.getItem("brandsense_token_expire") &&
        now.getTime() >
          parseInt(localStorage.getItem("brandsense_token_expire"))
      ) {
        logoutUser(true);
      }
    }, 1000);
    return () => {
      clearInterval(interval);
    };
  }, []);

  useEffect(() => {
    let parsed = typeof value === "string" ? JSON.parse(value) : value;
    if (!isEqual(parsed, state)) {
      dispatch({
        type: SYNC_REQUEST,
        payload: parsed,
      });
    }
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  useEffect(() => {
    set(state);
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state]);

  const loginUser = async () => {
    const now = new Date();
    localStorage.setItem(
      "brandsense_token_expire",
      now.getTime() + TOKEN_EXPIRATION
    );
    dispatch({ type: LOGIN });
  };

  const logoutUser = (isExpire) => {
    dispatch({ type: LOGOUT });
    localStorage.removeItem("user");
    localStorage.removeItem("brandsense_token_expire");
    clearAllCookies();
    navigate(`/${isExpire ? "?token_expire=true" : ""}`);
  };
  const getUserInfo = async () => {
    // dispatch({ type: GET_USER_INFO, payload: {} });

    const res = await getUserInfoApi();
    dispatch({ type: GET_USER_INFO, payload: res.payload });
  };

  return (
    <AuthContext.Provider
      value={{ ...state, loginUser, logoutUser, getUserInfo }}
    >
      {children}
    </AuthContext.Provider>
  );
};
