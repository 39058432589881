import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { getPostThumb } from "api";
import { formatDistanceToNow } from "date-fns";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import RingLoader from "react-spinners/RingLoader";
import ClipLoader from "react-spinners/ClipLoader";
import { FaTrashAlt } from "react-icons/fa";
import { navigate } from "@reach/router";
import { css } from "@emotion/react";
import { deletePostApi } from "api";
import { usePostContext } from "contexts";
import { AiFillPushpin, AiOutlinePushpin } from "react-icons/ai";

export const TableRow = ({
  title,
  status,
  duration,
  channel,
  exceptions,
  post_hash,
  creation_time,
  isLoading,
  pin = false,
  onDelete = () => {},
  onPin = () => {},
}) => {
  const [isLoadingThumb, setIsLoadingThumb] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [thumbSrc, setThumbSrc] = useState("");
  const { deletePost } = usePostContext();
  const fetchThumb = async (params) => {
    setIsLoadingThumb(true);
    const { success, payload } = await getPostThumb(params);
    if (success) {
      setThumbSrc(payload);
    } else {
      setThumbSrc("");
    }
    setIsLoadingThumb(false);
  };

  useEffect(() => {
    if (post_hash) {
      fetchThumb(post_hash);
    }
    return () => {};
  }, [post_hash]);
  const handleDelete = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDeleting(true);
    const res = await deletePostApi(post_hash);
    if (res.success) {
      deletePost(post_hash);
    }
    setIsDeleting(false);
    onDelete();
  };
  const handlePin = (e) => {
    e.preventDefault();
    e.stopPropagation();
    onPin(post_hash);
  };

  const handleRowClick = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (!isDeleting) {
      navigate(`/post/${post_hash}`);
    }
  };
  return isLoading ? (
    <SkeletonTheme color="#202020" highlightColor="#444">
      <Container isLoading={isLoading}>
        <RowItem>
          <Skeleton height={60} />
        </RowItem>
        <RowItem>
          <Skeleton height={32} />
        </RowItem>
        <RowItem>
          <Skeleton height={32} />
        </RowItem>
        <RowItem>
          <Skeleton height={32} />
        </RowItem>
        <RowItem>
          <Skeleton height={32} />
        </RowItem>
        <RowItem>
          <Skeleton height={32} />
        </RowItem>
        <RowItem>
          <Skeleton height={32} />
        </RowItem>
      </Container>
    </SkeletonTheme>
  ) : (
    <Container onClick={(e) => handleRowClick(e)}>
      <RowItem className="d-flex">
        {thumbSrc ? (
          <Image src={`data:image/jpeg;base64,${thumbSrc}`} alt={title} />
        ) : isLoadingThumb ? (
          <RingLoader
            color="white"
            size={40}
            css={css`
              margin: auto;
            `}
          />
        ) : (
          <ImagePlaceholder />
        )}
      </RowItem>
      <RowItem>{title}</RowItem>
      <RowItem>{status}</RowItem>
      <RowItem>{`${Math.round(duration ?? 0)} seconds`}</RowItem>
      <RowItem>{channel ?? ""}</RowItem>
      <RowItem>
        {!exceptions || !exceptions?.length ? "N/A" : exceptions.join(", ")}
      </RowItem>
      <RowItem>
        {creation_time
          ? formatDistanceToNow(new Date(creation_time), { addSuffix: true })
          : ""}
      </RowItem>
      <RowIcon className="mr-3" onClick={handlePin}>
        {pin ? <AiFillPushpin size={20} /> : <AiOutlinePushpin size={20} />}
      </RowIcon>
      <RowIcon>
        {isDeleting ? (
          <ClipLoader color="white" size={20} />
        ) : (
          <FaTrashAlt onClick={(e) => handleDelete(e)} />
        )}
      </RowIcon>
    </Container>
  );
};

const Container = styled.div`
  background: ${(props) => props.theme.palette.items};
  display: flex;
  padding: 0 32px 0 32px;
  height: 80px;
  color: white;
  transition: all 300ms ease;
  font-size: ${(props) => props.theme.palette.m};
  border: 1px solid ${(props) => props.theme.palette.gray};
  margin: 3px 6px 3px 12px;
  cursor: ${(props) => (props.isLoading ? "initial" : "pointer")};
  border-radius: 2px;
  &:hover {
    background: ${(props) =>
      props.isLoading
        ? props.theme.palette.items
        : props.theme.palette.itemsHover};
  }
  & > div:nth-child(1) {
    width: 120px;
  }
  & > div:nth-child(2) {
    width: 200px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  & > div:nth-child(3) {
    width: 120px;
  }
  & > div:nth-child(4) {
    width: 150px;
  }
  & > div:nth-child(5) {
    width: 150px;
  }
  & > div:nth-child(6) {
    flex: 1;
  }
  & > div:nth-child(7) {
    width: 140px;
  }
`;

const ImagePlaceholder = styled.div`
  width: 120px;
  height: 60px;
  background: #f6c4c4;
`;
const Image = styled.img`
  width: 120px;
  height: 60px;
  object-fit: contain;
`;
const RowItem = styled.div`
  margin: auto 32px auto 0;
`;
const RowIcon = styled.div`
  color: white;
  margin: auto 0;
  cursor: pointer;
  transition: all 300ms ease;
  display: flex;
  &:hover {
    color: #2a90af;
  }
`;
