import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { usePostContext } from "contexts";
import { API_URL } from "config";
import {
  Player,
  ControlBar,
  ReplayControl,
  ForwardControl,
  CurrentTimeDisplay,
  TimeDivider,
  LoadingSpinner,
  PlaybackRateMenuButton,
  VolumeMenuButton,
} from "video-react";
import "./style.scss";

export const VideoPlayer = ({ postId, player, playerContainer, source }) => {
  const [token, setToken] = useState("");
  const { post } = usePostContext();
  useEffect(() => {
    setToken(localStorage.getItem("user"));
    console.log("video url", `${API_URL}/api/watch/${postId}?jwt=${token}`);

    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return token ? (
    <Container ref={playerContainer}>
      {source === "raw" ? (
        <Player
          ref={player}
          src={`${API_URL}/api/watch/${postId}?jwt=${token}`}
          // poster={`${API_URL}/api/thumb/${postId}?jwt=${token}`}
          disableDefaultControls={post && post.credit_size === 1}
          disableCompletely={post && post.credit_size === 1}
        >
          <ControlBar
            autoHide={false}
            disableCompletely={post && post.credit_size === 1}
            disableDefaultControls={post && post.credit_size === 1}
          >
            <LoadingSpinner />
            <ReplayControl seconds={5} order={1.1} />
            <ForwardControl seconds={10} order={1.2} />
            <CurrentTimeDisplay order={4.1} />
            <TimeDivider order={4.2} />
            <PlaybackRateMenuButton rates={[5, 2, 1, 0.5, 0.1]} order={7.1} />
            <VolumeMenuButton />
          </ControlBar>
        </Player>
      ) : source === "thumbnail" ? (
        <Img back={`${API_URL}/api/thumb/${postId}?jwt=${token}`} />
      ) : // ) : source === "full" ? (
      //   <Img back={`${API_URL}/api/full/${postId}?jwt=${token}`} />
      source === "micro" ? (
        <Img back={`${API_URL}/api/micro/${postId}?jwt=${token}`} />
      ) : null}
    </Container>
  ) : null;
};

const Container = styled.div`
  /* height: 100%; */
  /* position: relative;
  padding-top: 56.25%; Player ratio: 100 / (1280 / 720) */
  flex: 1;
`;

const Img = styled.div`
  width: 100%;
  height: 100%;
  background: url(${(props) => props.back}) black no-repeat center;
  object-fit: contain;
  object-position: center;
  background-size: auto 100%;
`;
