import React from "react";
import styled from "styled-components";

export const Footer = () => {
  return (
    <Container>
      <div className="my-auto">PanelBot by Deep.ad</div>
      <Link
        href="https://www.deep.ad/privacy-policy"
        target="_blank"
        className="ml-3 my-auto"
      >
        Privacy Policy
      </Link>
      <Link
        href="https://www.deep.ad/tos"
        target="_blank"
        className="ml-3 my-auto"
      >
        Terms of Service
      </Link>
      <Link
        href="https://deepad.atlassian.net/servicedesk/customer/portal/3"
        target="_blank"
        className="ml-3 my-auto"
       >
         Feedback
       </Link>
    </Container>
  );
};

const Container = styled.div`
  background: ${(props) => props.theme.palette.backgrounds};
  height: 50px;
  color: white;
  font-size: ${(props) => props.theme.font.size.sm};
  padding: 0 12px;
  display: flex;
  border-top: 2px solid ${(props) => props.theme.palette.canvas};
`;
const Link = styled.a`
  font-size: ${(props) => props.theme.font.size.sm};
  color: white;
  text-decoration: underline;
  transition: all 300ms ease;
  cursor: pointer;
  &:hover {
    opacity: 0.7;
    color: ${(props) => props.theme.palette.primary};
    cursor: pointer;
  }
`;
