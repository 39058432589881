import React, { useEffect } from "react";
import styled from "styled-components";
import { Button, SearchInput } from "components";
import { usePostContext } from "contexts";
import { parseQuery } from "utils";
import { TAG_PREFIXES } from "config";
import { ReportModal } from "modals";
import { useModal } from "hooks";
import { DURATION_MIN, DURATION_MAX, SPECIAL_DATES } from "config";

export const SearchBar = ({
  onSearch = () => {},
  onDownload = () => {},
  onUploadURL = () => {},
}) => {
  const {
    queryStartUploadDate,
    queryEndUploadDate,
    queryStartCreationDate,
    queryEndCreationDate,
    queryTags,
    querySearch,
    queryDuration,
    query,
    setQuery,
    setQuerySearch,
    setQueryUploadDates,
    setQueryCreationDates,
    setQueryDuration,
    replaceQueryTags,
    isLoading,
  } = usePostContext();
  const reportModal = useModal();

  useEffect(() => {
    let tmp = "";
    if (
      (queryStartUploadDate && queryEndUploadDate) ||
      (SPECIAL_DATES.includes(queryStartUploadDate) && !queryEndUploadDate)
    ) {
      tmp += `upload-time:${queryStartUploadDate}..${queryEndUploadDate} `;
    }
    if (
      (queryStartCreationDate && queryEndCreationDate) ||
      (SPECIAL_DATES.includes(queryStartCreationDate) && !queryEndCreationDate)
    ) {
      tmp += `creation-time:${queryStartCreationDate}..${queryEndCreationDate} `;
    }
    if (
      !(
        queryDuration.min === DURATION_MIN && queryDuration.max === DURATION_MAX
      )
    ) {
      tmp += `duration:${queryDuration.min}..${queryDuration.max} `;
    }
    if (queryTags.length) {
      TAG_PREFIXES.forEach((prefix) => {
        let sub = queryTags.filter((tag) => tag.startsWith(prefix));
        if (sub.length) {
          tmp += `tag:${sub.join(",")} `;
        }
      });
    }
    if (querySearch) {
      tmp += querySearch;
    }
    setQuery(tmp);
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    queryTags,
    queryStartUploadDate,
    queryEndUploadDate,
    queryStartCreationDate,
    queryEndCreationDate,
    querySearch,
    queryDuration,
  ]);

  const handleChangeSearch = (params) => {
    const { uploadDates, creationDates, tags, search, duration } =
      parseQuery(params);
    console.log("query", params, parseQuery(params));
    setQuerySearch(search);
    setQueryUploadDates(uploadDates);
    setQueryCreationDates(creationDates);
    setQueryDuration(duration);
    replaceQueryTags(tags);
  };
  const handleDownload = () => {
    reportModal.openModal();
  };
  // const handleDelete = () => {};
  return (
    <Container>
      <LeftPanel>
        <SearchContainer>
          <SearchInput
            placeholder="Search"
            className="my-auto w-100"
            value={query}
            onChange={handleChangeSearch}
            onEnter={() => onSearch(query)}
            disabled={isLoading}
          />
        </SearchContainer>
        <Button
          size="normal"
          buttonTheme="dark"
          width="fit-content"
          className="my-auto ml-3"
          disabled={isLoading}
          onClick={() => onSearch(query)}
        >
          Search
        </Button>
      </LeftPanel>
      <RightPanel>
        <Button
          size="normal"
          width="fit-content"
          disabled={!query || isLoading}
          className="my-auto"
          onClick={handleDownload}
          data-for="player-tools"
          data-tip="Download reports"
        >
          Download Reports
        </Button>
        {/* <Button
          size="normal"
          width="fit-content"
          className="my-auto ml-3"
          onClick={onUploadURL}
          data-for="player-tools"
          data-tip="Upload urls"
          disabled={isLoading}
        >
          Upload URLs
        </Button> */}
      </RightPanel>
      <ReportModal {...reportModal} />
    </Container>
  );
};

const Container = styled.div`
  background: ${(props) => props.theme.palette.backgrounds};
  height: 50px;
  display: flex;
  padding: 0 24px;
`;
const LeftPanel = styled.div`
  flex: 1;
  padding-left: 0px;
  padding-right: 24px;
  display: flex;
`;
const SearchContainer = styled.div`
  display: flex;
  flex: 1;
`;
const RightPanel = styled.div`
  border-left: 4px solid ${(props) => props.theme.palette.canvas};
  padding: 0 0 0 24px;
  display: flex;
`;
