import React from "react";
import theme from "theme";
import { ThemeProvider } from "styled-components";
import { Router } from "@reach/router";
import {
  LoginPage,
  HomePage,
  DetailPage,
  RegisterPage,
  ResetPage,
  ForgotPage,
  NotFoundPage,
} from "pages";
import { PrivateRoute, PublicRoute } from "components";
import { AuthProvider, PostProvider, UploadProvider } from "contexts";
import ReactTooltip from "react-tooltip";

import "theme/all.scss";
import "bootstrap/dist/css/bootstrap.min.css";

function App() {
  return (
    <div className="App">
      <ThemeProvider theme={theme}>
        <AuthProvider>
          <PostProvider>
            <UploadProvider>
              <Router>
                <PublicRoute as={LoginPage} path="/" />
                <PublicRoute as={NotFoundPage} path="/404" />
                <PublicRoute as={LoginPage} path="/login" />
                <PublicRoute as={RegisterPage} path="/register" />
                <PublicRoute as={ResetPage} path="/reset-password" />
                <PublicRoute as={ForgotPage} path="/forgot-password" />
                <PrivateRoute as={HomePage} path="/home" />
                <PrivateRoute as={DetailPage} path="/post/:id" />
              </Router>
              <ReactTooltip
                id="player-tools"
                multiline
                place="bottom"
                effect="solid"
                className="player-tools"
              />
            </UploadProvider>
          </PostProvider>
        </AuthProvider>
      </ThemeProvider>
    </div>
  );
}

export default App;
