import { createContext, useContext, useReducer, useEffect } from "react";
import { useLocalstorage } from "rooks";
import PostReducer from "./PostReducer";
import { formatDistanceToNowStrict } from "date-fns";
import { DURATION_MIN, DURATION_MAX } from "config";
import { extractFilters } from "utils";
import { isEqual } from "lodash";
import {
  SET_LOADING,
  QUERY_POSTS,
  SET_ERROR,
  GET_POST,
  QUERY_INIT,
  SET_QUERY,
  INIT_POST,
  SET_QUERY_UPLOAD_DATES,
  SET_QUERY_CREATION_DATES,
  SET_QUERY_TAGS,
  SET_QUERY_SEARCH,
  REPLACE_QUERY_TAGS,
  SET_QUERY_DURATION,
  DELETE_POST,
  SET_POST_FETCHING,
  ADD_POST,
  FETCH_MORE_POSTS,
  SYNC_REQUEST,
} from "./../types";
import { queryPostsApi, getPostApi } from "api";
const PostContext = createContext();
export const usePostContext = () => useContext(PostContext);
const STORAGE_KEY = "post-state";

export const PostProvider = (props) => {
  const initialState = {
    query: "",
    posts: [],
    filters: {
      Status: [],
      Channel: [],
      Exceptions: [],
      Duration: [],
      "Global Tags": [],
      "Private Tags": [],
      Categories: [],
      Brands: [],
      Features: [],
      Mediums: [],
      Confirmations: [],
      Manufacturers: [],
    },
    post: {},
    totalPosts: 0,
    remainingPosts: 0,
    loadingTime: 0,
    currentPage: 0,
    error: "",
    isLoading: false,
    isFetching: false,
    queryStartUploadDate: "",
    queryEndUploadDate: "",
    queryStartCreationDate: "",
    queryEndCreationDate: "",
    querySearch: "",
    queryTags: [],
    queryDuration: { min: DURATION_MIN, max: DURATION_MAX },
  };

  const [value, set] = useLocalstorage(STORAGE_KEY, initialState);
  const [pinData, setPinData] = useLocalstorage("post-pin-state", []);

  const [state, dispatch] = useReducer(PostReducer, value);

  useEffect(() => {
    console.log("post state updated", state);
    set(state);
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state]);

  useEffect(() => {
    let parsed = typeof value === "string" ? JSON.parse(value) : value;
    let newValue = JSON.parse(localStorage.getItem(STORAGE_KEY)) || {};
    console.log("post storage updated", parsed, newValue, state);
    if (isEqual(parsed, newValue) && !isEqual(parsed, state)) {
      dispatch({
        type: SYNC_REQUEST,
        payload: parsed,
      });
    }
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);
  const queryPosts = async (query = "", loading = true) => {
    loading && setPostLoading();
    const beforeTime = new Date();
    let res = [];
    try {
      if (query.startsWith("tags:")) {
        res = await queryPostsApi(query);
        // res = await queryTagsApi(query);
      } else {
        res = await queryPostsApi(query);
      }
      const filters = extractFilters(res.payload.stubs, res.payload.tags);
      console.log("query posts", res.payload.posts);
      dispatch({
        type: res.success ? QUERY_POSTS : SET_ERROR,
        payload: {
          posts: res.payload.posts,
          remainingPosts: res.payload.total - res.payload.posts.length,
          query,
          filters,
          loadingTime: formatDistanceToNowStrict(beforeTime),
        },
      });
    } catch (error) {
      dispatch({
        type: SET_ERROR,
        payload: {},
      });
    }
  };

  const setQuery = (payload) => {
    dispatch({
      type: SET_QUERY,
      payload,
    });
  };
  const setQuerySearch = (payload) => {
    dispatch({
      type: SET_QUERY_SEARCH,
      payload,
    });
  };
  const setQueryUploadDates = (payload) => {
    dispatch({
      type: SET_QUERY_UPLOAD_DATES,
      payload,
    });
  };
  const setQueryDuration = (payload) => {
    dispatch({
      type: SET_QUERY_DURATION,
      payload,
    });
  };
  const setQueryCreationDates = (payload) => {
    dispatch({
      type: SET_QUERY_CREATION_DATES,
      payload,
    });
  };

  const setQueryTags = (payload) => {
    dispatch({
      type: SET_QUERY_TAGS,
      payload,
    });
  };

  const replaceQueryTags = (payload) => {
    dispatch({
      type: REPLACE_QUERY_TAGS,
      payload,
    });
  };

  const deletePost = (payload) => {
    pinData && setPinData(pinData.filter((item) => item !== payload));
    dispatch({
      type: DELETE_POST,
      payload,
    });
  };

  const addPost = (payload) => {
    dispatch({
      type: ADD_POST,
      payload,
    });
  };

  const getPost = async (id) => {
    dispatch({
      type: INIT_POST,
    });
    setPostLoading();
    const res = await getPostApi(id);
    console.log("post", res.payload);
    dispatch({
      type: res.success ? GET_POST : SET_ERROR,
      payload: res?.payload,
    });
  };

  const initPosts = async () => {
    dispatch({
      type: QUERY_INIT,
    });
  };

  // Set Loading
  const setPostLoading = (params = true) =>
    dispatch({ type: SET_LOADING, payload: params });

  // Set Fetching
  const setPostFetching = (params = true) =>
    dispatch({ type: SET_POST_FETCHING, payload: params });

  // Fetch more
  const fetchMorePosts = async () => {
    const beforeTime = new Date();
    console.log("fetch more posts...");
    setPostFetching();
    try {
      const res = await queryPostsApi(state.query, state.currentPage + 1);
      console.log("more posts", res);
      const filters = extractFilters(res.payload.stubs, res.payload.tags);
      dispatch({
        type: res.success ? FETCH_MORE_POSTS : SET_ERROR,
        payload: {
          posts: res.payload.posts,
          remainingPosts: res.payload.total - res.payload.posts.length,
          filters,
          loadingTime: formatDistanceToNowStrict(beforeTime),
        },
      });
    } catch (error) {
      dispatch({
        type: SET_ERROR,
        payload: {},
      });
    }
  };

  const { children } = props;

  return (
    <PostContext.Provider
      value={{
        ...state,
        setQueryUploadDates,
        setQueryCreationDates,
        setQueryTags,
        queryPosts,
        getPost,
        setQuery,
        initPosts,
        setQuerySearch,
        replaceQueryTags,
        setQueryDuration,
        deletePost,
        addPost,
        setPostLoading,
        setPostFetching,
        fetchMorePosts,
        pinData,
        setPinData,
      }}
    >
      {children}
    </PostContext.Provider>
  );
};
