import React from "react";
import { Section, Product } from "./../components";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";

export const ProductSection = ({ isLoading, data }) => {
  return isLoading ? (
    <Section title="Products">
      <SkeletonTheme color="#202020" highlightColor="#444">
        <Skeleton count={3} />
      </SkeletonTheme>
    </Section>
  ) : data?.length > 0 ? (
    <Section title="Products">
      <div className="mt-3 d-flex flex-wrap">
        {data.map((product, index) => (
          <Product
            key={`${index}key`}
            logo={product.brand}
            image={product.image || ""}
            brandName={product.name}
            description={product.description}
            roi={product.roi_hash}
            price={product?.offers?.length > 0 ? "" : ""}
            productName={product.product}
            product={product}
          />
        ))}
      </div>
    </Section>
  ) : null;
};
