import { createContext, useContext, useReducer, useEffect } from "react";
import { useLocalstorage } from "rooks";
import axios from "axios";
import UploadReducer from "./UploadReducer";
import { ACCEPT_MEDIA_FILE_TYPES, MAX_FILE_UPLOAD_SIZE } from "config";
import { last } from "lodash";
import { uploadFiles, uploadURLs } from "api";
import { fromString as fromRTF2HTML } from "@iarna/rtf-to-html";
import HTML2Text from "html-to-formatted-text";
import { parseCSV, isJSON } from "utils";
import * as XLSX from "xlsx";
import { extractRawText } from "mammoth";
import { parseStringPromise } from "xml2js";
// import { isEqual } from "lodash";

import {
  INIT_UPLOAD,
  ADD_UPLOAD,
  REMOVE_UPLOAD,
  SET_UPLOAD_STATE,
  SET_UPLOAD_TITLE,
  SET_UPLOAD_OPEN,
  SET_UPLOAD_FILE_STATE,
  SET_UPLOADED_COUNT,
  INCREASE_UPLOADED_COUNT,
  CANCEL_UPLOAD,
  RETRY_UPLOAD,
  SET_UPLOAD_MINIMIZED,
  SET_UPLOAD_FILE_EXPAND,
  // SYNC_REQUEST,
} from "./../types";
const UploadContext = createContext();
export const useUploadContext = () => useContext(UploadContext);
const STORAGE_KEY = "upload-state";

export const UploadProvider = (props) => {
  const initialState = {
    content: [],
    state: "", //uploading, error, uploaded, canceled
    title: "",
    uploadedCount: 0,
    open: false,
    minimized: false,
  };
  const [value, set] = useLocalstorage(STORAGE_KEY, initialState);
  const [state, dispatch] = useReducer(UploadReducer, value);

  useEffect(() => {
    const processUploading = async () => {
      if (!state) return;
      const { state: uploadingState, content } = state;
      if (
        ["uploading", "error"].includes(uploadingState) &&
        content.filter(({ state }) => ["uploading", "parsing"].includes(state))
          .length === 0
      ) {
        const file = content.find(({ state }) => state === "ready");
        if (file) {
          const { category, data, id } = file;
          if (uploadingState === "uploading") {
            setUploadTitle(
              `Uploading ${
                content.filter(
                  ({ state }) => state === "ready" || state === "uploading"
                ).length
              }  assets`
            );
          }

          setUploadFileState({
            id,
            state: category === "file" ? "parsing" : "uploading",
          });

          let res;
          if (category === "file") {
            res = await parseFile(file);
          } else if (category === "media") {
            res = await uploadFiles([data]);
          } else if (category === "url") {
            res = await uploadURLs(data + "\n");
          }

          if (res?.success) {
            if (["media", "url"].includes(file.category)) {
              if (["uploading", "error"].includes(uploadingState)) {
                if (res.posts && res.posts[0]?.isExists) {
                  setUploadFileState({
                    id,
                    state: "duplicateError",
                    ...res.posts[0],
                  });
                } else if (res.posts && res.posts[0]?.error) {
                  setUploadFileState({
                    id,
                    state: "failed",
                  });
                } else {
                  setUploadFileState({
                    id,
                    state: "uploaded",
                    ...res.posts[0],
                  });
                }
              }
            }
            // else {
            //   if (["uploading", "error"].includes(uploadingState)) {
            //     setUploadFileState({
            //       id,
            //       state: "parsed",
            //     });
            //   }
            // }
          } else {
            if (["url", "asset"].includes(category)) {
              setUploadFileState({
                id,
                state: "failed",
                // message: res.payload.toString(),
              });
            }
          }
        } else {
          setUploadState("uploaded");
          setUploadTitle(
            `${
              content.filter(({ state }) =>
                ["uploaded", "duplicateError"].includes(state)
              ).length
            }  uploads complete ${
              content.filter(({ state }) =>
                ["typeError", "sizeError", "badUrl"].includes(state)
              ).length > 0
                ? `, ${
                    content.filter(({ state }) =>
                      ["typeError", "sizeError", "badUrl"].includes(state)
                    ).length
                  } errors`
                : ""
            } `
          );
          setUploadedCount(0);
        }
      }
    };

    const interval = setInterval(() => {
      processUploading();
    }, 500);
    return () => {
      clearInterval(interval);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state]);

  const startUploading = async () => {};

  const stopUploading = () => {};

  useEffect(() => {
    set(state);
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state]);

  // useEffect(() => {
  //   let parsed = typeof value === "string" ? JSON.parse(value) : value;
  //   let newValue = JSON.parse(localStorage.getItem(STORAGE_KEY)) || {};
  //   console.log("upload storage updated", parsed, newValue, state);
  //   if (isEqual(parsed, newValue) && !isEqual(parsed, state)) {
  //     dispatch({
  //       type: SYNC_REQUEST,
  //       payload: parsed,
  //     });
  //   }
  //   return () => {};
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [value]);

  const initUploads = async () => {
    dispatch({
      type: INIT_UPLOAD,
      payload: initialState,
    });
  };

  const addUploads = async (files, typeParam = "file", parent) => {
    let payload = [];
    payload = await Promise.all(
      files.map(async (file) => {
        const fileExt =
          typeParam === "file" ? last(file.path.split("."))?.toLowerCase() : "";
        const fileType =
          typeParam === "file"
            ? ACCEPT_MEDIA_FILE_TYPES.find(({ name }) => name === fileExt)
                ?.type || "unknown"
            : typeParam;
        const fileSize = typeParam === "file" ? file.size : 0;
        let isValidUrl = true;
        if (typeParam === "url") {
          const urlRes = await axios.get(
            `https://9szols3ok1.execute-api.us-east-1.amazonaws.com/prod?url=${file}`
          );
          console.log("url check", urlRes);
          isValidUrl = urlRes.data;
        }
        return {
          category:
            fileType === "file" ? "file" : fileType === "url" ? "url" : "media",
          //media: video, image, ..
          //url: url
          //file: docx, xlsx, csv, ...
          type: fileType === "file" ? fileExt : fileType,
          data: file,
          name: typeParam === "file" ? file.name : file,
          parent: parent,
          expanded: false,
          state:
            fileType === "unknown"
              ? "typeError"
              : fileSize > MAX_FILE_UPLOAD_SIZE
              ? "sizeError"
              : !isValidUrl
              ? "badUrl"
              : "ready",
          // ready, uploading, failed, uploaded, canceled, lowCredit, badUrl
        };
      })
    );
    console.log("add upload", payload);
    dispatch({
      type: ADD_UPLOAD,
      payload,
    });
  };

  const parseFile = async ({ type, data, id }) => {
    const urlRegex = /(https?:\/\/[^\s]+)/g;
    let urls = [];
    switch (type) {
      case "txt":
        const content = await new Response(data).text();
        urls = content.match(urlRegex);
        console.log("parsed text file", urls);
        if (urls) {
          addUploads(
            urls.map((url) => url.trim()),
            "url",
            id
          );
        }
        setUploadFileState({
          id,
          state: "parsed",
        });
        break;
      case "json":
        const jsonContent = await new Response(data).text();
        const isJson = await isJSON(jsonContent);
        console.log("is json", isJson);
        urls = jsonContent.match(urlRegex);
        console.log("parsed json file", urls);
        addUploads(
          urls.map((url) => {
            if (["'", '"'].includes(last(url))) {
              return url.substring(0, url.length - 1);
            } else {
              return url;
            }
          }),
          "url",
          id
        );
        setUploadFileState({
          id,
          state: "parsed",
        });
        break;

      case "xml":
        const xmlContent = await new Response(data).text();
        console.log("xml", xmlContent);
        try {
          let res = await parseStringPromise(xmlContent);
          urls = JSON.stringify(res, null, " ").match(urlRegex);
          console.log("parsed xml file", urls);
          addUploads(
            urls.map((url) => {
              if (["'", '"'].includes(last(url))) {
                return url.substring(0, url.length - 1);
              } else {
                return url;
              }
            }),
            "url",
            id
          );
          setUploadFileState({
            id,
            state: "parsed",
          });
        } catch (error) {}
        break;
      case "rtf":
        const rtfContent = await new Response(data).text();
        fromRTF2HTML(rtfContent, (err, doc) => {
          console.log("parsed rtf file", err, HTML2Text(doc).match(urlRegex));
          addUploads(HTML2Text(doc).match(urlRegex), "url", id);
          setUploadFileState({
            id,
            state: "parsed",
          });
        });
        break;
      case "csv":
      case "tsv":
      case "tab":
        urls = await parseCSV(data);
        console.log("parsed csv file", urls);
        addUploads(urls, "url", id);
        setUploadFileState({
          id,
          state: "parsed",
        });
        break;
      case "xls":
      case "ods":
      case "xlsx":
        const reader = new FileReader();
        reader.onload = (evt) => {
          // evt = on_file_select event
          /* Parse data */
          const bstr = evt.target.result;
          const wb = XLSX.read(bstr, { type: "binary" });
          /* Get first worksheet */
          const wsname = wb.SheetNames[0];
          const ws = wb.Sheets[wsname];
          /* Convert array of arrays */
          const data = XLSX.utils.sheet_to_csv(ws, { header: 1 });
          /* Update state */
          urls = data.match(urlRegex).map((url) => {
            if (last(url) === ",") {
              return url.slice(0, url.length - 1);
            }
            return url;
          });
          console.log("xlsx parsed", urls); // shows that excel data is read
          addUploads(urls, "url", id);
          setUploadFileState({
            id,
            state: "parsed",
          });
        };
        reader.readAsBinaryString(data);
        break;
      // case "doc":
      case "docx":
        const docReader = new FileReader();
        docReader.onload = async (evt) => {
          try {
            const arrayBuffer = docReader.result;
            const result = await extractRawText({ arrayBuffer: arrayBuffer });
            urls = result.value.match(urlRegex);
            console.log("docx parsed", urls);
            if (urls) {
              addUploads(urls, "url", id);
              setUploadFileState({
                id,
                state: "parsed",
              });
            }
          } catch (error) {
            console.log("docx parsing error", error);
            setUploadFileState({
              id,
              state: "parseFailed",
            });
          }
        };
        docReader.readAsArrayBuffer(data);
        break;
      default:
        break;
    }
  };

  const removeUploads = async (params) => {
    dispatch({
      type: REMOVE_UPLOAD,
      payload: params,
    });
  };

  const setUploadState = async (params) => {
    dispatch({
      type: SET_UPLOAD_STATE,
      payload: params,
    });
  };
  const setUploadFileState = async (params) => {
    dispatch({
      type: SET_UPLOAD_FILE_STATE,
      payload: params,
    });
  };

  const setUploadTitle = async (params) => {
    dispatch({
      type: SET_UPLOAD_TITLE,
      payload: params,
    });
  };
  const setUploadedCount = async (params) => {
    dispatch({
      type: SET_UPLOADED_COUNT,
      payload: params,
    });
  };
  const setUploadMinimized = async (params) => {
    dispatch({
      type: SET_UPLOAD_MINIMIZED,
      payload: params,
    });
  };
  const setUploadFileExpand = async (params) => {
    dispatch({
      type: SET_UPLOAD_FILE_EXPAND,
      payload: params,
    });
  };

  const increaseUploadedCount = async () => {
    dispatch({
      type: INCREASE_UPLOADED_COUNT,
    });
  };

  const openUpload = async () => {
    dispatch({
      type: SET_UPLOAD_OPEN,
      payload: true,
    });
  };
  const closeUpload = async () => {
    dispatch({
      type: SET_UPLOAD_OPEN,
      payload: false,
    });
  };

  const cancelUpload = async () => {
    dispatch({
      type: CANCEL_UPLOAD,
    });
  };

  const retryUpload = async () => {
    dispatch({
      type: RETRY_UPLOAD,
    });
  };

  const { children } = props;

  return (
    <UploadContext.Provider
      value={{
        ...state,
        initUploads,
        addUploads,
        removeUploads,
        setUploadState,
        setUploadTitle,
        openUpload,
        closeUpload,
        startUploading,
        stopUploading,
        setUploadedCount,
        setUploadMinimized,
        increaseUploadedCount,
        cancelUpload,
        retryUpload,
        setUploadFileExpand,
      }}
    >
      {children}
    </UploadContext.Provider>
  );
};
