import React from "react";
import { useAuthContext } from "contexts";
import { Redirect } from "@reach/router";

export const PublicRoute = ({ as: Component, ...props }) => {
  const { isLogined } = useAuthContext();
  return (
    <div>
      {isLogined || localStorage.getItem("user") ? (
        <Redirect to="/home" noThrow />
      ) : (
        <Component {...props} />
      )}
    </div>
  );
};
