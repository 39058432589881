import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import { MainLayout } from "layouts";
import { useParams, navigate } from "@reach/router";
import { usePostContext } from "contexts";
// import { usePostContext, useAuthContext } from "contexts";
// import { useInput } from "hooks";
// import { sumBy } from "lodash";
// import ColorScale from "color-scales";
// import { Warning, ScrollView, SearchBar } from "components";
import { ScrollView, SearchBar } from "components";
import { useLocation } from "@reach/router";
import { last } from "lodash";
// import { Toolbar, Section, Summary, Tab } from "./components";
import { Toolbar } from "./components";
import { getAllROI, thumbnailPostApi, debugPostApi } from "api";
// import { roiApi } from "api";
import {
  // EventsTimeline,
  // ValuesPanel,
  // Stats,
  // TextVisual,
  // TextAudio,
  // RegionInterest,
  // WebSection,
  PlayerFragment,
  BrandSection,
  ProductSection,
  OfferSection,
  CapturePane,
  CelebritiesSection,
  MetadataSection,
  SummarySection,
  TextSection,
} from "./fragments";

// const tabData = ["Share of Voice", "Pixel Time of Brands"];

export const DetailPage = (props) => {
  const { queryPosts } = usePostContext();
  const { getPost, post, isLoading, setPostLoading } = usePostContext();
  // const [postSource, setPostSource] = useState("raw");
  // const { userInfo } = useAuthContext();
  // const currentTab = useInput(tabData[0]);
  // const [webData, setWebData] = useState([]);
  // const [shareVoices, setShareVoices] = useState([]);
  // const [pixelTime, setPixelTime] = useState([]);
  const params = useParams();
  const player = useRef();
  const playerContainer = useRef();
  const [enableCapture, setEnableCapture] = useState(false);
  // const [roiData, setRoiData] = useState([]);
  const location = useLocation();
  // const [isCaptureUploading, setIsCaptureUploading] = useState(false);
  const [texts, setTexts] = useState([]);

  const init = async () => {
    try {
      await getPost(params.id);
      const res = await getAllROI(last(location.pathname.split("/")));
      if (res.success) {
        console.log("roi", res.payload);
        // setRoiData(res.payload);
      } else {
        // setRoiData([]);
      }
    } catch (error) {
      console.log("detail page error", error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  };

  useEffect(() => {
    init();
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.id]);

  useEffect(() => {
    if (post.cert) {
      let temp = post?.cert?.meta?.objects?.matching_urls || [];
      temp.push(...(post?.cert?.meta?.objects?.visually_similar_urls || []));
      temp.push(...(post?.cert?.meta?.objects?.webs || []));
      // setWebData(temp);
      if (post?.cert?.meta?.super_brands.length > 0) {
        // let colorScale = new ColorScale(0, 100, ["#FFFF70", "#927F03"]);

        // let brands = post.cert.meta.super_brands.map((brand, index) => {
        //   return {
        //     value: brand.fpp / sumBy(post.cert.meta.super_brands, (d) => d.fpp),
        //     name: brand.brand,
        //     color: colorScale
        //       .getColor((100 / post.cert.meta.super_brands.length) * index)
        //       .toHexString(),
        //   };
        // });
        // setShareVoices(brands);

        // const pixels = [
        //   ...post.cert.meta.super_brands.map((brand, index) => {
        //     return {
        //       value: brand.fpp,
        //       name: brand.brand,
        //     };
        //   }),
        //   {
        //     name: "Unbranded",
        //     value: 1 - sumBy(post.cert.meta.super_brands, (d) => d.fpp),
        //   },
        // ];
        // setPixelTime(
        //   pixels.map((brand, index) => {
        //     return {
        //       value: brand.value,
        //       name: brand.name,
        //       color: colorScale
        //         .getColor((100 / brands.length) * index)
        //         .toHexString(),
        //     };
        //   })
        // );
        let temp = [];
        if (post?.cert?.meta?.ultra_texts) {
          Object.keys(post?.cert?.meta?.ultra_texts).forEach((key) => {
            temp.push(post?.cert?.meta?.ultra_texts[key].simple);
          });
          setTexts(temp);
        }
        setTexts(temp);
      }
    }
    return () => {};
  }, [post]);

  // const handleEventClick = (frame) => {
  //   if (player.current) {
  //     player.current.seek(frame / 30);
  //   }
  // };

  const handleCapture = async (params) => {
    setEnableCapture(false);
    // setIsCaptureUploading(true);
    // const res = await roiApi(last(location.pathname.split("/")), params);
    // setRoiData(res.payload);
    // setIsCaptureUploading(false);
  };

  // const handleRemove = (params) => {
  //   setRoiData(params);
  // };

  const handleDebug = async () => {
    setPostLoading();
    await debugPostApi(params.id);
    await init();
  };

  const handleThumbnail = async () => {
    setPostLoading();
    await thumbnailPostApi(params.id);
    await init();
  };

  const handleSearch = async (params) => {
    navigate("/home");
    await queryPosts(params);
  };

  return (
    <MainLayout>
      <SearchBar
        isLoaded={!isLoading}
        // tags={queryFilters}
        onSearch={handleSearch}
        // onUploadURL={handleUploadURL}
      />
      <Toolbar
        isLoading={isLoading}
        title={post.title}
        onDebug={handleDebug}
        onThumbnail={handleThumbnail}
      />
      <MainContent>
        <LeftSection>
          <PlayerFragment
            postId={params.id}
            player={player}
            isLoading={isLoading}
            post={post}
            playerContainer={playerContainer}
            onCapture={() => setEnableCapture(true)}
          />
          <MetadataSection post={post} isLoading={isLoading} />
        </LeftSection>
        <RightSection>
          <InnerContent>
            <SummarySection post={post} isLoading={isLoading} />
            <BrandSection
              data={post?.cert?.meta?.super_brands || []}
              isLoading={isLoading}
            />
            <CelebritiesSection
              data={post?.cert?.meta?.super_celebs || []}
              isLoading={isLoading}
            />
            <OfferSection
              isLoading={isLoading}
              data={post?.cert?.meta?.super_offers || []}
            />
            <ProductSection
              isLoading={isLoading}
              data={post?.cert?.meta?.super_products || []}
            />
            <TextSection isLoading={isLoading} post={post} texts={texts} />
          </InnerContent>
        </RightSection>
      </MainContent>
      <CapturePane
        enableCapture={enableCapture}
        playerRef={player}
        playerContainerRef={playerContainer}
        onCapture={handleCapture}
        onClose={() => setEnableCapture(false)}
        postId={params.id}
        creditSize={post?.credit_size}
      />
    </MainLayout>
  );
};

const MainContent = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
  overflow: auto;
  background: ${(props) => props.theme.palette.canvas};
  /* border-top: 3px solid ${(props) => props.theme.palette.backgrounds}; */
`;

const InnerContent = styled(ScrollView)`
  display: flex;
  flex-direction: column;
`;

const LeftSection = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 4px;
  width: 800px;
  min-width: 800px;
  /* width: 1200px; */
`;

const RightSection = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`;
