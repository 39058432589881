import { API } from "utils";
import { POST_COUNT_PER_PAGE } from "config";

export const queryPostsApi = async (
  query = "",
  page = 0,
  limit = POST_COUNT_PER_PAGE
) => {
  try {
    const response = await API().get(
      "api/posts/" +
        query +
        `?offset=${page * POST_COUNT_PER_PAGE}&limit=${limit}`
    );
    return { success: true, payload: response.data };
  } catch (e) {
    return { success: false, payload: [], error: e };
  }
};

export const queryTagsApi = async (query = "") => {
  const formData = new FormData();
  formData.append("search", query);
  try {
    const response = await API().post("api/tags", formData);
    return { success: true, payload: response.data.tags };
  } catch (e) {
    return { success: false, payload: [], error: e };
  }
};

export const getPostApi = async (id = "") => {
  try {
    const response = await API().get("api/post/" + id);
    return { success: true, payload: response.data };
  } catch (e) {
    return { success: false, payload: {}, error: e };
  }
};

export const getPostThumb = async (param) => {
  try {
    const response = await API().get(`api/thumb/${param}.png`, {
      responseType: "arraybuffer",
    });
    return {
      success: true,
      payload: Buffer.from(response.data, "binary").toString("base64"),
    };
  } catch (e) {
    return { success: false, payload: "", error: e };
  }
};

export const deletePostApi = async (id) => {
  try {
    const response = await API().post(`api/delete_post/${id}`);
    return { success: true, payload: response.data };
  } catch (e) {
    return { success: false, payload: {}, error: e };
  }
};

export const thumbnailPostApi = async (id) => {
  try {
    const response = await API().post(`api/post_thumbnail/${id}`);
    return { success: true, payload: response.data };
  } catch (e) {
    return { success: false, payload: {}, error: e };
  }
};

export const debugPostApi = async (id) => {
  try {
    const response = await API().post(`api/post_debug/${id}`);
    return { success: true, payload: response.data };
  } catch (e) {
    return { success: false, payload: {}, error: e };
  }
};
