import { GET_USER_INFO, LOGIN, LOGOUT, SYNC_REQUEST } from "./../types";

export default function AuthReducer(state, action) {
  switch (action.type) {
    case GET_USER_INFO:
      return {
        ...state,
        userInfo: action.payload,
        isLoading: false,
      };
    case LOGIN:
      return {
        isLogined: true,
        isLoading: false,
        userInfo: {},
      };
    case LOGOUT:
      return {
        isLogined: false,
        isLoading: false,
        userInfo: {},
      };

    case SYNC_REQUEST:
      return action.payload;
    default:
      return state;
  }
}
