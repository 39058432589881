import React from "react";
import styled from "styled-components";

export const Section = ({ className, title, children }) => {
  return (
    <Container className={className}>
      <Header>
        <span className="my-auto">{title}</span>
      </Header>
      <Content>{children}</Content>
    </Container>
  );
};

const Container = styled.div`
  /* width: 100%; */
  margin: 2px;
`;
const Header = styled.div`
  height: 44px;
  padding: 0 24px;
  display: flex;
  color: white;
  text-transform: uppercase;
  font-size: 20px;
  background-color: ${(props) => props.theme.palette.backgrounds};
  border-bottom: 1px solid ${(props) => props.theme.palette.gray};
`;
const Content = styled.div`
  padding: 16px 24px;
  background: ${(props) => props.theme.palette.backgrounds};
  color: white;
  font-size: 14px;
`;
