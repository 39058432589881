import React from "react";
import styled from "styled-components";
import { Accordion, CheckBox, DateRange, Duration } from "components";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import { usePostContext } from "contexts";
import "rc-slider/assets/index.css";
import "./style.scss";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file

export const FilterSection = ({ filterOptions, isLoading, onChange }) => {
  const {
    setQueryTags,
    queryTags,
    queryStartCreationDate,
    queryEndCreationDate,
    setQueryCreationDates,
    queryDuration,
    setQueryDuration,
    // queryStartUploadDate,
    // queryEndUploadDate,
    // setQueryUploadDates,
  } = usePostContext();
  const handleFilter = (params) => {
    setQueryTags(params);
  };

  const isAccordionOpen = (options = []) => {
    return (
      options.filter((option) => queryTags.includes(option.name)).length > 0
    );
  };

  return (
    <AccordionSection>
      {isLoading ? (
        <SkeletonTheme color="#202020" highlightColor="#444">
          <Skeleton count={16} height={24} className="my-2" />
        </SkeletonTheme>
      ) : (
        <>
          {/* <Accordion title="Status">
            {filterOptions?.Status?.data.map(({ label }) => (
              <CheckBox key={label}>{label}</CheckBox>
            ))}
          </Accordion> */}
          {/* <Accordion title="Channel">
            {filterOptions?.Channel?.data.map(({ label }) => (
              <CheckBox key={label}>{label}</CheckBox>
            ))}
          </Accordion> */}
          <Accordion
            title="Exceptions"
            desc={`${filterOptions?.Exceptions?.data?.length || 0} exceptions`}
            className="mb-3"
            open={isAccordionOpen(filterOptions?.Exceptions?.data)}
          >
            {filterOptions?.Exceptions?.data &&
              filterOptions?.Exceptions?.data.map(({ label, name }) => (
                <CheckBox
                  key={name}
                  value={queryTags.includes(name)}
                  onChange={() => handleFilter(name)}
                >
                  {label}
                </CheckBox>
              ))}
          </Accordion>
          {/* <Accordion
            title="Upload Date"
            className="mb-3"
            open={queryStartUploadDate || queryEndUploadDate}
          >
            <div className="py-3">
              <DateRange
                startDate={queryStartUploadDate}
                endDate={queryEndUploadDate}
                onChange={setQueryUploadDates}
              />
            </div>
          </Accordion> */}
          <Accordion
            title="Creation Date"
            className="mb-3"
            open={queryStartCreationDate || queryEndCreationDate}
          >
            <div className="py-3">
              <DateRange
                className="pt-2"
                startDate={queryStartCreationDate}
                endDate={queryEndCreationDate}
                onChange={setQueryCreationDates}
              />
            </div>
          </Accordion>
          <Accordion title="Duration" desc="in seconds" className="mb-3">
            <div className="py-3">
              <Duration value={queryDuration} onChange={setQueryDuration} />
            </div>
          </Accordion>
          <Accordion title="Global Tags" desc={`0 tags`} className="mb-3">
            {/* {tags.map((tag) => (
              <CheckBox
                value={filters.includes(`private.tag.${tags}`)}
                onChange={() => handleFilter(`private.tag.${tag}`)}
                key={tag}
              >
                {tag}
              </CheckBox>
            ))} */}
          </Accordion>
          <Accordion title="Private Tags" desc={`0 tags`} className="mb-3">
            {/* {tags.map((tag) => (
              <CheckBox
                value={filters.includes(`private.tag.${tag}`)}
                onChange={() => handleFilter(`private.tag.${tag}`)}
                key={tag}
              >
                {tag}
              </CheckBox>
            ))} */}
          </Accordion>
          <Accordion
            title="Categories"
            desc={`${filterOptions?.Categories?.data?.length || 0} categories`}
            className="mb-3"
            open={isAccordionOpen(filterOptions?.Categories?.data)}
          >
            {filterOptions?.Categories?.data &&
              filterOptions?.Categories?.data.map(({ label, name }) => (
                <CheckBox
                  value={queryTags.includes(name)}
                  onChange={() => handleFilter(name)}
                  key={name}
                >
                  {label}
                </CheckBox>
              ))}
          </Accordion>
          <Accordion
            title="Brands"
            desc={`${filterOptions?.Brands?.data?.length || 0} brands`}
            className="mb-3"
            open={isAccordionOpen(filterOptions?.Brands?.data)}
          >
            {filterOptions?.Brands?.data &&
              filterOptions?.Brands?.data.map(({ label, name }) => (
                <CheckBox
                  value={queryTags.includes(name)}
                  onChange={() => handleFilter(name)}
                  key={name}
                >
                  {label}
                </CheckBox>
              ))}
          </Accordion>
          <Accordion
            title="Features"
            desc={`${filterOptions?.Features?.data?.length || 0} features`}
            className="mb-3"
            open={isAccordionOpen(filterOptions?.Features?.data)}
          >
            {filterOptions?.Features?.data &&
              filterOptions?.Features?.data.map(({ label, name }) => (
                <CheckBox
                  value={queryTags.includes(name)}
                  onChange={() => handleFilter(name)}
                  key={name}
                >
                  {label}
                </CheckBox>
              ))}
          </Accordion>
          <Accordion
            title="Mediums"
            desc={`${filterOptions?.Mediums?.data?.length || 0} mediums`}
            className="mb-3"
            open={isAccordionOpen(filterOptions?.Mediums?.data)}
          >
            {filterOptions?.Mediums?.data &&
              filterOptions?.Mediums?.data.map(({ label, name }) => {
                return (
                  <CheckBox
                    value={queryTags.includes(name)}
                    onChange={() => handleFilter(name)}
                    key={name}
                  >
                    {label}
                  </CheckBox>
                );
              })}
          </Accordion>
          <Accordion
            title="Confirmations"
            desc={`${filterOptions?.Confirmations?.data?.length || 0} mediums`}
            className="mb-3"
            open={isAccordionOpen(filterOptions?.Confirmations?.data)}
          >
            {filterOptions?.Confirmations?.data &&
              filterOptions?.Confirmations?.data.map(({ label, name }) => {
                return (
                  <CheckBox
                    value={queryTags.includes(name)}
                    onChange={() => handleFilter(name)}
                    key={name}
                  >
                    {label}
                  </CheckBox>
                );
              })}
          </Accordion>
          {/* <Accordion
            title="Manufacturers"
            // desc={`${filterOptions?.Manufacturers?.data.length} manufacturers`}
          >
            {filterOptions?.Manufacturers?.data.map(({ label }) => (
              <CheckBox key={label}>{label}</CheckBox>
            ))}
          </Accordion> */}
        </>
      )}
    </AccordionSection>
  );
};

const AccordionSection = styled.div`
  margin: 12px 12px 12px 24px;
  padding-right: 10px;
  overflow: auto;
  min-width: 240px;
  flex: 1;
  ::-webkit-scrollbar {
    width: 10px;
    background: ${(props) => props.theme.palette.background};
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: ${(props) => props.theme.palette.background};
    border: 1px solid ${(props) => props.theme.palette.itemsHover};
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: ${(props) => props.theme.palette.itemsHover};
    cursor: pointer;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #555;
    cursor: pointer;
  }
`;
