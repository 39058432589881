/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import styled from "styled-components";
import { VideoPlayer, DropDown } from "components";
import ReactTooltip from "react-tooltip";
import { useInput } from "hooks";
import { usePostContext } from "contexts";
// import { FaPhotoVideo } from "react-icons/fa";
// import { FaCamera } from "react-icons/fa";
// import {
//   AiOutlineZoomOut,
//   AiOutlineZoomIn,
//   AiOutlineRotateLeft,
//   AiOutlineRotateRight,
// } from "react-icons/ai";
// import { BsFillInfoCircleFill } from "react-icons/bs";
// import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import { Box } from "./../components";
const sourceData = [
  { id: "raw", title: "Watch" },
  // { id: "full", title: "Full" },
  { id: "micro", title: "Micro" },
  { id: "thumbnail", title: "Thumbnail" },
];
export const PlayerFragment = ({
  postId,
  player,
  playerContainer,
  onCapture,
  isLoading,
}) => {
  const source = useInput(sourceData[0].id);
  const { post } = usePostContext();

  // const [metadata, setMetadata] = useState("");

  // const handleZoomIn = () => {};
  // const handleZoomOut = () => {};

  useEffect(() => {
    // if (Object.keys(post).length) {
    //   let temp = `Dimensions:  ${post?.width}x${
    //     post?.height
    //   }<br/> Fps:  ${post?.fps?.toFixed(
    //     2
    //   )}fps <br/> Duration:  ${post?.duration?.toFixed(2)}s <br/>
    //   Container:  ${post?.container} <br/>
    //   Decoder:  ${post?.decoder} <br/>
    //   ${post?.audio ? `Audio:  ${post?.audio} <br/>` : ""}
    //   `;
    //   setMetadata(temp);
    // }
    if (post && post.credit_size !== 1) {
      source.setValue("raw");
    } else {
      source.setValue("thumbnail");
    }
    return () => {};
  }, [post]);

  return (
    <Container>
      {/* <Tools>
        <SkeletonTheme color="#202020" highlightColor="#444">
          <ToolIcon
            data-for="player-tools"
            data-tip="Capture region of interest"
          >
            {isLoading ? (
              <Skeleton
                circle
                className="my-2 mx-auto"
                height={24}
                width={24}
              />
            ) : (
              <FaCamera size={24} onClick={onCapture} />
            )}
          </ToolIcon>
          <ToolIcon data-for="player-tools" data-tip="Zoom out">
            {isLoading ? (
              <Skeleton
                circle
                className="my-2 mx-auto"
                height={24}
                width={24}
              />
            ) : (
              <AiOutlineZoomOut size={24} onClick={handleZoomOut} />
            )}
          </ToolIcon>
          <ToolIcon data-for="player-tools" data-tip="Zoom in">
            {isLoading ? (
              <Skeleton
                circle
                className="my-2 mx-auto"
                height={24}
                width={24}
              />
            ) : (
              <AiOutlineZoomIn size={24} onClick={handleZoomIn} />
            )}
          </ToolIcon>
          <ToolIcon data-for="player-tools" data-tip="Rotate left">
            {isLoading ? (
              <Skeleton
                circle
                className="my-2 mx-auto"
                height={24}
                width={24}
              />
            ) : (
              <AiOutlineRotateLeft size={24} onClick={handleZoomIn} />
            )}
          </ToolIcon>
          <ToolIcon data-for="player-tools" data-tip="Rotate right">
            {isLoading ? (
              <Skeleton
                circle
                className="my-2 mx-auto"
                height={24}
                width={24}
              />
            ) : (
              <AiOutlineRotateRight size={24} onClick={handleZoomIn} />
            )}
          </ToolIcon>
          <ToolIcon data-for="player-tools" data-tip={metadata}>
            {isLoading ? (
              <Skeleton
                circle
                className="my-2 mx-auto"
                height={24}
                width={24}
              />
            ) : (
              <BsFillInfoCircleFill size={24} />
            )}
          </ToolIcon>
        </SkeletonTheme>
      </Tools> */}
      <PlayerContainer>
        <Header>
          <DropDown {...source} data={sourceData} width={120} />
        </Header>
        <VideoPlayer
          postId={postId}
          player={player}
          playerContainer={playerContainer}
          source={source.value}
        />
      </PlayerContainer>
      <ReactTooltip
        id="player-tools"
        className="player-tools"
        place="top"
        multiline
        effect="solid"
        brand-tooltip="#000"
      />
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
  height: 442px;
`;
const Header = styled.div`
  height: 30px;
  display: flex;
  margin-bottom: 12px;
`;
// const Tools = styled(Box)``;

// const ToolIcon = styled.div`
//   cursor: pointer;
//   transition: all 300ms ease;
//   margin: 0px auto 18px auto;
//   &:hover {
//     opacity: 0.7;
//   }
// `;

const PlayerContainer = styled(Box)`
  flex: 1;
  min-width: 600px;
  display: flex;
  flex-direction: column;
`;
